import { observable, action } from 'mobx';

class Login {
    @observable success = false;
    @observable name = "";
    @observable givenName = "";
    @observable familyName = "";
    @observable email = "";
    @observable googleId = "";
    @observable picture = "";

    @action setSuccess(data) {
        this.success = true;
        if(data != null){
            this.email = data.email;
            this.name = data.name;
            this.givenName = data.given_name;
            this.familyName = data.family_name;
            this.googleId = data.googleId;
            this.picture = data.picture;
        }
    }
}
export let login = new Login();