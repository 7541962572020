import React from 'react';
import close64 from '../img/close64.png';
import correct64 from '../img/correct64.png';
import print64 from '../img/print64.png';
import { observer } from 'mobx-react';
import { Form, Button, InputGroup, Container, Row, Col} from 'react-bootstrap';
import Barcode from "react-hooks-barcode";
import QRCode from "qrcode.react";
import NumericInput from 'react-numeric-input';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { provision } from '../models/provision';
import { barcodes } from '../models/provision';
import BrowswerPrint from '../BrowserPrint-3.0.216.min.js'
import axios from "axios";
// import ScriptTag from 'react-script-tag';

var selected_device;
var devices = [];

function setup()
{
	//Get the default device from the application as a first step. Discovery takes longer to complete.
    BrowswerPrint.getDefaultDevice("printer", 
        function(device){
            //Add device to list of devices and to html select element
            selected_device = device;
            devices.push(device);
            // alert(device);
            
            // //Discover any other devices available to the application
            // BrowswerPrint.getLocalDevices(function(device_list){
            // 	for(var i = 0; i < device_list.length; i++)
            // 	{
            // 		//Add device to list of devices and to html select element
            // 		var device = device_list[i];
            // 		if(!selected_device || device.uid != selected_device.uid)
            // 		{
            // 			devices.push(device);
            // 		}
            // 	}
                
            // }, function(){alert("Error getting local devices")},"printer");
            
        }, function(error){
            alert(error);
        });
}

// const Demo = props => (
//     <ScriptTag type="text/javascript" src="BrowserPrint-Zebra-1.0.216.min.js" onLoad="setup"/>
//     )

const toggleBarcodes = () => {
    console.log("Toggle Barcodes");
    barcodes.toggleShow()
  }
  
  const onChangeSID = (value) => {
    console.log("change SID:" + value);
    provision.setSID(value);
    console.log(provision.SIDPlain);
  }
  
  const onChangeSIDP = (value) => {
    console.log("change SIDP: " + value);
    provision.setSIDP(value);
  }
  
  const onChangeBand = (value) => {
    console.log("change band: " + value);
    provision.setBand(value);
  }
  
  const onChangeSensor = (value) => {
    console.log("change sensor: " + value);
    provision.setSensor(value)
  }

  const onChangeSerial = (value) => {
    console.log("change serial: " + value);
    provision.setSerial(value)
  }

  const onChangeSystemNumber = (value) => {
    console.log("change system number: " + value);
    provision.setSystemNumber(value)
  }
 
  var sidPrimeFormat = "^XA^FO150,10^BQ,2,4^FDQA,%s^FS^FO350,70^A0N,40,40^FDSID PRIME^FS^XZ"
  var sidFormat = "^XA^BY2,3^FO250,10^BCN,60,N,N,^FD>:%s^FS^FO130,90^ABN,40,40^FD%s^FS^FO230,150^A0N,30,30^FDCUSTOMER ACTIVATION CODE^FS^XZ";
  var serialFormat = "^XA^BY1,2^FO250,10^BCN,60,N,N,^FD>:%s^FS^FO150,100^A0N,35,35^FD%s^FS^FO310,150^A0N,30,30^FDSERIAL NUMBER^FS^XZ";
  // var dataToWrite = "^XA^FO150,10^BQ,2,4^FDQA,MDAwMDAwMDAwMDAxw8aQa4YxFLZOC4DGCTD+PGq/q+jmgLIJZg==^FS^FO350,60^A0N,30,30^FDSID PRIME^FS^FO550,10^BQ,2,4^FDQA,MDAwMDAwMDAwMDAxw8aQa4YxFLZOC4DGCTD+PGq/q+jmgLIJZg==^FS^XZ"

  function printSID(){
    if(!provision.validSID){
        alert("Invalid SID");
        return;
    }
    if(selected_device != null){
        selected_device.send(getSidData(provision.SID), undefined, function(error){
            alert(error);
        });
    }
  }

  function printSIDP(){
    if(!provision.validSIDP){
        alert("Invalid SID Prime");
        return;
    }
    if(selected_device != null){
        selected_device.send(getSidPrimeData(provision.SIDP), undefined, function(error){
            alert(error);
        });
    }
  }

  function printSerial(){
    if(!provision.validSerial){
        alert("Invalid Serial Number");
        return;
    }
    if(selected_device != null){
        selected_device.send(getSerialData(provision.serial), undefined, function(error){
            alert(error);
        });
    }
  }

  function printBandMac(){
    if(!provision.validBand){
        alert("Invalid Band Mac Address");
        return;
    }
    if(selected_device != null){
        selected_device.send(getMacAddressData(provision.band), undefined, function(error){
            alert(error);
        });
    }
  }

  function printSensorMac(){
    if(!provision.validSensor){
        alert("Invalid Sensor Mac Address");
        return;
    }
    if(selected_device != null){
        selected_device.send(getMacAddressData(provision.sensor), undefined, function(error){
            alert(error);
        });
    }
  }
  
  function printAll(){
    if(!provision.validSID){
        alert("Invalid SID");
        return;
    }
    if(!provision.validSIDP){
        alert("Invalid SID Prime");
        return;
    }
    if(!provision.validSerial){
        alert("Invalid Serial Number");
        return;
    }
    if(selected_device != null){
        selected_device.send(getSidData(provision.SID), undefined, function(error){
            alert(error);
        });
        selected_device.send(getSidPrimeData(provision.SIDP), undefined, function(error){
            alert(error);
        });
        selected_device.send(getSerialData(provision.serial), undefined, function(error){
            alert(error);
        });
    }
  }

  function getSidPrimeData(sidPrime){
      return `^XA^FO150,10^BQ,2,4^FDQA,${sidPrime}^FS^FO350,70^A0N,40,40^FDSID PRIME^FS^XZ`
  }

  function getSidData(sid){
      return `^XA^BY2,3^FO250,10^BCN,60,N,N,^FD>:${sid}^FS^FO280,100^A0N,40,40^FD${sid}^FS^FO230,150^A0N,30,30^FDCUSTOMER ACTIVATION CODE^FS^XZ`
  }

  function getSerialData(serial){
      return `^XA^BY1,2^FO250,10^BCN,60,N,N,^FD>:${serial}^FS^FO150,100^A0N,35,35^FD${serial}^FS^FO310,150^A0N,30,30^FDSERIAL NUMBER^FS^XZ`
  }

  function getMacAddressData(mac){
    return `^XA^BY2,3^FO200,50^BCN,60,N,N,^FD>:${mac}^FS^XZ`
  }

  const printAllBarcodes = () => {
      console.log("Print all barcodes")
      if( provision.validSID && provision.validSIDP && provision.validSerial ){
        printAll();
      }else{
          alert("SID, SID Prime or Serial number is not valid")
      }
  }

  const newSID = () => {
    console.log("Load SID");
    // Go fetch SID information
    // alert(location + "sid/new");
    axios.post(location + "sid/new")
        .then(function (response) {
            provision.setSIDPlain(response.data.SystemID);
            provision.setSIDP(response.data.SIDPrime);
            console.log(response.data)
        })
        .catch(function (error) {
            alert(error.message);
            if (error.response) {
              // The request was made and the server responded with a status code
              // that falls out of the range of 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
    
  }
  const submitProvision = () => {
    console.log("Assign SID");
    if( provision.validSID && provision.validSIDP && 
        provision.validBand && provision.validSensor && provision.validSerial ){
        // Provision the sid with the data
        axios.post(location + "sid/assign", {
            "SystemID": provision.SIDPlain,
            "BandMac": provision.band,
            "SensorMac": provision.sensor,
            "SerialNumber": provision.serial})
            .then(function (response) {
                provision.incSystemNumber();
                console.log(response)
            })
            .catch(function (error) {
                alert(error.message);
                if (error.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  console.log(error.response.data);
                  console.log(error.response.status);
                  console.log(error.response.headers);
                } else if (error.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  console.log(error.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  console.log('Error', error.message);
                }
                console.log(error.config);
              });
    } else {
        alert(`Provision data is not valid.`)
    }
  }
  
  const qrconfig = {
    size: 48
  }

  const config = {
    background: "#f5f5f5",
    height: 25,
    width: 2,
    fontOptions: "italic",
    margin: 0,
    displayValue: false
  };

  const config2 = {
    background: "#f5f5f5",
    height: 40,
    width: 2,
    fontOptions: "bold",
    margin: 5,
    displayValue: true
  };

  const config3 = {
    background: "#f5f5f5",
    height: 40,
    width: 1.2,
    fontOptions: "bold",
    margin: 5,
    displayValue: true
  };

  const labelPrepend = {
    width: "100px"
  }

  const labelPrependWide = {
    width: "150px"
  }

  const controlHeight ={
      height: "40px"
  }

  const controlHeightLarge ={
    height: "60px"
}

@observer
export default class Provision extends React.Component {

    componentDidMount() {
        setup();
    }
   
    render(){
        var showBarcodes = (barcodes.show) ? "" : "d-none";
        return (
            <Form className="text-left mx-2 my-2">
            <InputGroup className="my-3">
            <InputGroup.Prepend style={labelPrepend}>
                <InputGroup.Text style={labelPrepend}>
                SID
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control className="input-lg" type="text" placeholder="XXX-XXX-XXX" id="SID"
                maxLength="11" 
                value={provision.SID}
                onChange={e => onChangeSID(e.target.value)}/>
            <Button onClick={newSID}>New</Button>
            <InputGroup.Append className={showBarcodes}>
                <InputGroup.Text>
                    <div className="align-items-center mx-2">
                        <img src={print64} width="24px" height="24px" alt="print" onClick={printSID}/>
                    </div>
                    <Barcode value={(provision.SID == "") ? "-" : provision.SID} {...config}/>
                </InputGroup.Text>
            </InputGroup.Append>
            <div className="d-flex flex-wrap align-items-center mx-1">
                <img src={(provision.validSID) ? correct64 : close64} width="28px" height="28px" alt="valid"  />
            </div>
            </InputGroup>

            <InputGroup className="my-3">
            <InputGroup.Prepend style={labelPrepend}>
                <InputGroup.Text style={labelPrepend}>
                SID Prime
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control style={controlHeightLarge} className="input-lg" type="text" placeholder="" id="SIDP"
                maxLength="52"
                value={provision.SIDP}
                onChange={e => onChangeSIDP(e.target.value)} />
            <InputGroup.Append className={showBarcodes}>
                <InputGroup.Text>
                    <div className="align-items-center mx-2">
                        <img src={print64} width="24px" height="24px" alt="print" onClick={printSIDP}/>
                    </div>
                    <QRCode value={(provision.SIDP == "") ? "-" : provision.SIDP} {...qrconfig} />
                </InputGroup.Text>
            </InputGroup.Append>
            <div className="d-flex flex-wrap align-items-center mx-1">
                <img src={(provision.validSIDP) ? correct64 : close64} width="28px" height="28px" alt="valid"  />
            </div>
            </InputGroup>

            <InputGroup className="my-3">
            <InputGroup.Prepend style={labelPrepend}>
                <InputGroup.Text style={labelPrepend}>
                Band
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control className="input-lg" type="text" placeholder="FF:FF:FF:FF:FF:FF" id="BandMac" 
                maxLength="17"
                value={provision.band}
                onChange={e => onChangeBand(e.target.value)}/>
            <InputGroup.Append className={showBarcodes}>
                <InputGroup.Text>
                    <div className="align-items-center mx-2">
                        <img src={print64} width="24px" height="24px" alt="print" onClick={printBandMac}/>
                    </div>
                    <Barcode value={(provision.band == "") ? "-" : provision.band} {...config}/>
                </InputGroup.Text>
            </InputGroup.Append>
            <div className="d-flex flex-wrap align-items-center mx-1">
                <img src={(provision.validBand) ? correct64 : close64} width="28px" height="28px" alt="valid"  />
            </div>
            </InputGroup>

            <InputGroup className="my-3">
            <InputGroup.Prepend style={labelPrepend}>
                <InputGroup.Text style={labelPrepend}>
                Sensor
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control className="input-lg" type="text" placeholder="FF:FF:FF:FF:FF:FF" id="SensorMac" 
                maxLength="17"
                value={provision.sensor}
                onChange={e => onChangeSensor(e.target.value)}/>
            <InputGroup.Append className={showBarcodes}>
                <InputGroup.Text>
                    <div className="align-items-center mx-2">
                        <img src={print64} width="24px" height="24px" alt="print" onClick={printSensorMac}/>
                    </div>
                    <Barcode value={(provision.sensor == "") ? "-" : provision.sensor} {...config}/>
                </InputGroup.Text>
            </InputGroup.Append>
            <div className="d-flex flex-wrap align-items-center mx-1">
                <img src={(provision.validSensor) ? correct64 : close64} width="28px" height="28px" alt="valid"  />
            </div>
            </InputGroup>
            
            <InputGroup className="my-3">
            <InputGroup.Prepend style={labelPrepend}>
                <InputGroup.Text style={labelPrepend}>
                Serial #
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control style={controlHeightLarge} className="input-lg" type="text" placeholder="AAAAAAAAA-BBBB-CCCC-DDDD" id="serial" 
                maxLength="24"
                value={provision.serial}
                onChange={e => onChangeSerial(e.target.value)}/>
            <InputGroup.Append className={showBarcodes}>
                <InputGroup.Text>
                    <div className="align-items-center mx-2">
                        <img src={print64} width="24px" height="24px" alt="print" onClick={printSerial}/>
                    </div>
                    <QRCode value={(provision.serial == "") ? "-" : provision.serial} {...qrconfig} />
                </InputGroup.Text>
            </InputGroup.Append>
            <div className="d-flex flex-wrap align-items-center mx-1">
                <img src={(provision.validSerial) ? correct64 : close64} width="28px" height="28px" alt="valid"  />
            </div>
            </InputGroup>
            <div className="clearfix">
                <Container>
                    <Row>
                        <Col>
                            <Button onClick={submitProvision}>Submit</Button>
                        </Col>
                        <Col>
                            <Button className="float-right" onClick={printAllBarcodes}>Print All</Button>
                        </Col>
                        <Col>
                            <Button className="float-right" onClick={toggleBarcodes}>Barcodes</Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>                
                <InputGroup className="my-3">
                    <InputGroup.Prepend style={labelPrependWide}>
                        <InputGroup.Text style={labelPrependWide}>
                        Provision Date
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker className={"form-control"} selected={provision.dateSystemPackaged} onChange={date => provision.setDateSystemPackaged(date)} />
                </InputGroup>
                <InputGroup className="my-3">
                    <InputGroup.Prepend style={labelPrependWide}>
                        <InputGroup.Text style={labelPrependWide}>
                        Employee
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control style={controlHeight} as="select" value={provision.employeeNumber} onChange={e => provision.setEmployeeNumber(e.target.value)}>
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                        <option>04</option>
                        <option>05</option>
                        <option>06</option>
                        <option>07</option>
                        <option>08</option>
                        <option>09</option>
                        <option>10</option>
                    </Form.Control>
                </InputGroup>
                <InputGroup className="my-3">
                    <InputGroup.Prepend style={labelPrependWide}>
                        <InputGroup.Text style={labelPrependWide}>
                        System #
                        </InputGroup.Text>
                    </InputGroup.Prepend>

                    <NumericInput className={"form-control"} min={1} max={999} value={provision.systemNumber} step={1} onChange={value => provision.setSystemNumber(value)}/>
                </InputGroup>

                <InputGroup className="my-3">
                    <InputGroup.Prepend style={labelPrependWide}>
                        <InputGroup.Text style={labelPrependWide}>
                        Tablet Date
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker className={"form-control"} selected={provision.dateTabletRec} onChange={date => provision.setDateTabletRec(date)} />
                </InputGroup>

                <InputGroup className="my-3">
                    <InputGroup.Prepend style={labelPrependWide}>
                        <InputGroup.Text style={labelPrependWide}>
                        Band Date
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker className={"form-control"} selected={provision.dateBandRec} onChange={date => provision.setDateBandRec(date)} />
                </InputGroup>

                <InputGroup className="my-3">
                    <InputGroup.Prepend style={labelPrependWide}>
                        <InputGroup.Text style={labelPrependWide}>
                        Sensor Date
                        </InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker className={"form-control"} selected={provision.dateMSRec} onChange={date => provision.setDateMSRec(date)} />
                </InputGroup>
            </div>
            </Form>
        )
    }
}
