import React from 'react';
import gogoband from './img/gogoband.png';
import './App.css';
import { observer } from 'mobx-react';
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, InputGroup} from 'react-bootstrap';
import { GoogleLogin, GoogleLogout } from 'react-google-login';
import { login } from './models/login';
import Provision from './components/provision';
import axios from 'axios';

const successGoogleLogin = (response) => {
  console.log("Google Login Success Phase 1")
  console.log(response);
  axios.get(`https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${response.tokenId}`, {})
  .then((response) => {
    console.log("Google Login Success Phase 2")
    console.log(response);
    login.setSuccess(response.data);
  }, (error) => {
    console.log("Google Login Failure Phase 2")
    console.log(error);
    login.success = false;
  });
}
const failureGoogleLogin = (response) => {
  console.log("Google Login Failure Phase 1")
  console.log(response);
  login.success = false;
}
const successGoogleLogout = (response) => {
  console.log("Google Logout")
  console.log(response);
  login.success = false;
}


@observer
class App extends React.Component {
  render(){
    var showLogin = (login.success) ? "none" : "inherit";
    var showBody = (login.success) ? "inherit" : "none";
    return (
      <div className="App">
        <div className="my-5" style={{display: showLogin}}>
          <div className="my-2"><img src={gogoband} width="50" alt="GOGO Band" /> Console</div>
          <div>
            <GoogleLogin
              clientId="825583292251-dl56p33esqsk3rm4gae0u4l59v5jhk4b.apps.googleusercontent.com"
              buttonText="Login"
              hostedDomain="mygogoband.com"
              onSuccess={successGoogleLogin}
              onFailure={failureGoogleLogin}
            />
          </div>
        </div>
        <div style={{display: showBody}}>
          <Navbar bg="light" expand="lg">
            <Navbar.Brand href="#home"> <img src={gogoband} width="50" alt="GOGO Band" />&nbsp;Console</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="#home">Provision</Nav.Link>
                <NavDropdown title="Reports" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#reports/1">System Overview</NavDropdown.Item>
                  <NavDropdown.Item href="#reports/2">Training/Predictive</NavDropdown.Item>
                  <NavDropdown.Item href="#reports/3">Parent App Data</NavDropdown.Item>
                  <NavDropdown.Item href="#reports/4">Dry/Wet Stop/Alarm</NavDropdown.Item>
                  <NavDropdown.Item href="#reports/5">CP Night Analysis</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#reports/6">Major Model Stats</NavDropdown.Item>
                  <NavDropdown.Item href="#reports/7">Minor Model Stats</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#reports/8">Confusion Matrix</NavDropdown.Item>
                  <NavDropdown.Item href="#reports/9">Detailed Alarm</NavDropdown.Item>
                  <NavDropdown.Item href="#reports/10">Historic Model</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#reports/11">Daily Breakdown</NavDropdown.Item>
                  <NavDropdown.Item href="#reports/12">Daily Group</NavDropdown.Item>
                  <NavDropdown.Divider />  
                  <NavDropdown.Item href="#reports/13">Gateway State</NavDropdown.Item>
                  <NavDropdown.Item href="#reports/14">Exception Reports</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Action" id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action/1">Alarm</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3">Monitor</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/2">Model</NavDropdown.Item>
                  <NavDropdown.Item href="#action/4">SIDs</NavDropdown.Item>
                  <NavDropdown.Item href="#action/5">Stage</NavDropdown.Item>
                  <NavDropdown.Item href="#action/6">Suppress</NavDropdown.Item>
                </NavDropdown>
              </Nav>
              {login.givenName}
              <img src={login.picture} width="40px" height="40px" alt={login.name} />
              <GoogleLogout
                className="mx-2"
                clientId="825583292251-vg8nlq9a0n0r8kqpa452fkn3irc2ml43.apps.googleusercontent.com"
                buttonText="Logout"
                onLogoutSuccess={successGoogleLogout}
              />
              <Form inline>
                <FormControl type="text" placeholder="SID" className="mr-sm-2" />
              </Form>
            </Navbar.Collapse>
          </Navbar>
          <Provision />
        </div>
      </div>
    );
  }
}

export default App;
