import { observable, computed, action } from 'mobx';

class Barcodes {
    @observable show = true;
    @action toggleShow(){
        this.show = !this.show;
    }
}
export let barcodes = new Barcodes();

const serialLength = 24; //AAAAAAAAA-BBBB-CCCC-DDDD

class Provision {
    @observable SID = "";
    @computed get validSID(){
        return validateSID(this.SID);
    }
    @computed get SIDPlain(){
        return this.SID.replace(/-/gi, '');
    }
    @observable SIDP = "";
    @computed get validSIDP(){
        return validateSIDP(this.SIDP);
    }
    @observable band = "";
    @computed get validBand(){
        return validateMAC(this.band);
    }
    @observable sensor = "";
    @computed get validSensor(){
        return validateMAC(this.sensor);
    }    
    @observable serial = "";
    @computed get validSerial(){
        return validateSerial(this.serial);
    }
    @observable dateSystemPackaged = new Date();
    @computed get validDateSystemPackaged(){
        return validateDate(this.dateSystemPackaged);
    }
    @observable systemNumber = 1;
    @computed get validSystemNumber(){
        return validateSystemNumber(this.systemNumber);
    }
    @computed get systemNumberString(){
        return pad(this.systemNumber,3);
    }
    @observable employeeNumber = "01";
    @computed get validEmployeeNumber(){
        return validateEmployeeNumber(this.employeeNumber);
    }
    @observable dateTabletRec = new Date();
    @computed get validDateTabletRec(){
        return validateDate(this.dateTabletRec);
    }
    @observable dateBandRec = new Date();
    @computed get validDateBandRec(){
        return validateDate(this.dateBandRec);
    }
    @observable dateMSRec = new Date();
    @computed get validDateMSRec(){
        return validateDate(this.dateMSRec);
    }

    @action setSID(value){
        this.SID = value;
    }

    @action setSIDPlain(value){
        let splits = value.match(/.{1,3}/g);
        this.SID = splits.join('-')
    }
    
    @action setSIDP(value){
        this.SIDP = value;
    }

    @action setBand(value){
        this.band = value;
    }

    @action setSensor(value){
        this.sensor = value;
    }

    @action setSerial(value){
        this.serial = value;
        const vals = decodeSerial(value);
        if(vals === null){
            return;
        }
        if(vals[0] === null){
            return;
        }
        this.dateSystemPackaged = vals[0];
        this.systemNumber = vals[1];
        this.employeeNumber = vals[2];
        this.dateTabletRec = vals[3];
        this.dateBandRec = vals[4];
        this.dateMSRec = vals[5];
    }

    @action setDateSystemPackaged(value){
        this.dateSystemPackaged = value;
        this.serial = generateSerial(
            this.dateSystemPackaged,
            this.systemNumber,
            this.employeeNumber,
            this.dateTabletRec,
            this.dateBandRec,
            this.dateMSRec);
    }

    @action setSystemNumber(value){
        this.systemNumber = value;
        this.serial = generateSerial(
            this.dateSystemPackaged,
            this.systemNumber,
            this.employeeNumber,
            this.dateTabletRec,
            this.dateBandRec,
            this.dateMSRec);
    }

    @action incSystemNumber(value){
        this.systemNumber = this.systemNumber + 1;
        this.serial = generateSerial(
            this.dateSystemPackaged,
            this.systemNumber,
            this.employeeNumber,
            this.dateTabletRec,
            this.dateBandRec,
            this.dateMSRec);
    }

    @action setEmployeeNumber(value){
        this.employeeNumber = value;
        this.serial = generateSerial(
            this.dateSystemPackaged,
            this.systemNumber,
            this.employeeNumber,
            this.dateTabletRec,
            this.dateBandRec,
            this.dateMSRec);
    }

    @action setDateTabletRec(value){
        this.dateTabletRec = value
        this.serial = generateSerial(
            this.dateSystemPackaged,
            this.systemNumber,
            this.employeeNumber,
            this.dateTabletRec,
            this.dateBandRec,
            this.dateMSRec);
    }

    @action setDateBandRec(value){
        this.dateBandRec = value;
        this.serial = generateSerial(
            this.dateSystemPackaged,
            this.systemNumber,
            this.employeeNumber,
            this.dateTabletRec,
            this.dateBandRec,
            this.dateMSRec);
    }

    @action setDateMSRec(value){
        this.dateMSRec = value;
        this.serial = generateSerial(
            this.dateSystemPackaged,
            this.systemNumber,
            this.employeeNumber,
            this.dateTabletRec,
            this.dateBandRec,
            this.dateMSRec);
    }
}

function decodeSerial(serial){
    if(serial == null || serial.length !== serialLength){
        return [null, null, null, null, null, null];
    }
    const dateSystemPackaged = decodeDate(serial.substring(0,4));
    const systemNumber = decodeSystemNumber(serial.substring(4,7));
    const empNumber = decodeEmployeeNumber(serial.substring(7,9))
    // skip "-"
    const dateTablet = decodeDate(serial.substring(10,14))
    // skip "-"
    const dateBand = decodeDate(serial.substring(15,19))
    // skip "-"
    const dateMS = decodeDate(serial.substring(20,24))
    return [dateSystemPackaged,
        systemNumber,
        empNumber,
        dateTablet,
        dateBand, 
        dateMS];
}

function generateSerial(dateSystemPackaged, systemNumber, empNumber, dateTablet, dateBand, dateMS){
    return codifyDate(dateSystemPackaged) +
        codifySystemNumber(systemNumber) +
        codifyEmployeeNumber(empNumber) +
        "-" +
        codifyDate(dateTablet) +
        "-" +
        codifyDate(dateBand) +
        "-" +
        codifyDate(dateMS);
}

function decodeDate(value){
    if(value === null || value.length != 4){
        return null;
    }
    const month = decodeMonth(value.substring(0,1));
    const day = decodeMonth(value.substring(1,3));
    const year = decodeMonth(value.substring(3,4));
    if( month >= 0 && month <= 11 &&
        day >= 1 && day <= 31 &&
        year >= 2019 && year <= 2030 ){
        return new Date(year, month, day);
    }
    return null;
}

function codifyDate(date){
    return codifyMonth(date) + codifyDay(date) + codifyYear(date);
}

const months = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"]

function decodeMonth(value){
    if(value === null || value.length != 1){
        return null;
    }
    const findmonth = (element) => element = value;
    const month = months.findIndex(findmonth);
    if(month === -1){
        return null;
    }
    return month;
}

function codifyMonth(date){
    if(date === null){
        return "";
    }
    const month = date.getMonth();
    return months[month];
}

function decodeDay(value){
    if(value === null || value.length != 1){
        return null;
    }
    const num = Number(value);
    if(num >= 0 && num <= 31){
        return num
    }
    return null;
}

function codifyDay(date){
    if(date === null){
        return "";
    }
    return pad(date.getDate(date), 2);
}

const years = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"]

function decodeYear(value){
    if(value === null || value.length != 1){
        return null;
    }
    const findyear = (element) => element = value;
    const year = years.findIndex(findyear);
    if(year === -1){
        return null;
    }
    return 2019 + year;
}

function codifyYear(date){
    if(date === null){
        return "";
    }
    const year = date.getFullYear();
    if(year >= 2019 & year <= 2030){
        return years[year-2019];
    }
    return "";
}

function decodeSystemNumber(value){
    return value
}

function codifySystemNumber(systemNumber){
    return pad(systemNumber, 3);
}

function decodeEmployeeNumber(value){
    return value
}

function codifyEmployeeNumber(employeeNumber){
    return pad(employeeNumber, 2);
}

function pad(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

function validateSID(value){
    if(value ===  null){
        return false;
    }
    // ZZZ-ZZZ-ZZZ
    const regex = /^[A-Z0-9]{3}-{1}[A-Z0-9]{3}-{1}[A-Z0-9]{3}/g;
    return value.match(regex) != null;
}

function validateSIDP(value){
    if(value ===  null){
        return false;
    }
    if(value.length == 52){
        return true;
    }
    return false;
    // const regex = /^[A-Z0-9]{52}/g;
    // return value.match(regex) != null;
}

function validateMAC(value){
    if(value ===  null){
        return false;
    }
    // FF:FF:FF:FF:FF:FF
    const regex = /^[A-F0-9]{2}:{1}[A-F0-9]{2}:{1}[A-F0-9]{2}:{1}[A-F0-9]{2}:{1}[A-F0-9]{2}:{1}[A-F0-9]{2}/g;
    return value.match(regex) != null;
}

function validateSerial(value){
    if(value ===  null){
        return false;
    }
    // AAAAAAAAA-BBBB-CCCC-DDDD
    const regex = /^[A-Z0-9]{9}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}/g;
    return value.match(regex) != null;
}

function validDate(value){
    if(value ===  null){
        return false;
    }
    // MM-DD-YYYY
    const regex = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/g;
    return value.match(regex) != null;
}

function validateSystemNumber(value){
    if(value ===  null){
        return false;
    }
    // 000
    const regex = /^[0-9]{3}/g;
}

function validateEmployeeNumber(value){
    if(value ===  null){
        return false;
    }
    // 00
    const regex = /^[0-9]{2}/g;
}

export let provision = new Provision();